.price-switch-off.react-toggle--focus .react-toggle-track {
    background-color: #6c757d52;
}

.card-off{
    background-color: #edeeef;
    border-color: #6c757d;
}

.signature-pad{
    border: 1px solid #6c757d52;
    width:100%;
}