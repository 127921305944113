.top-row {
    margin-top: 25px;
}

.min-height-modal{
    .modal-content{
        min-height: 625px;
    }
}

.min-height-modal-400{
    .modal-content{
        min-height: 400px;
    }
}

.filterRow{
    padding-left: 40px;
}

.appAndModuleBigRow{
    width: 28%;
    text-align: left;
}

.appAndModuleSmallRow{
    width: 9%;
    text-align: right;
}

.appAndModuleTinyRow{
    width: 5%;
    text-align: center;
}

.appAndModuleMediumRow{
    width: 15%;
    text-align: center;
}
