.app-hover {
    box-shadow: rgba(9, 30, 66, 0.28) 0px 4px 8px -2px, rgba(9, 30, 66, 0.3) 0px 0px 1px;

    &:hover {
        background-color: #f2f2f2;
    }
}

.app-selected {
    box-shadow: rgba(9, 30, 66, 0.28) 0px 4px 8px -2px, rgba(9, 30, 66, 0.3) 0px 0px 1px;
    background-color: #f2f2f2;
}
